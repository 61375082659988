import { isDEVMODE, domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

export default class PageTransitionProduct extends Transition {

  onEnter({ to, trigger, done }) {

    if (isDEVMODE) console.log(`Transition Entering Page : ${to.dataset.taxiView}`)

    // Reset window scroll
    window.scrollTo(0, 0)
    // Updating the ScrollTriggers
    ScrollTrigger.refresh()
    // Display the new page
    done()
    // Removing body className while animating
    domStorage.body.classList.remove('is--animating')
    // Page intro animation
    pageIntro()

  }

  onLeave({ from, trigger, done }) {

    if (isDEVMODE) console.log(`Transition Leaving Page : ${from.dataset.taxiView}`)

    window.scrollTo(0, 0)

    // Remove the last page
    done()
  }
}
  