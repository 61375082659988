import { isDEVMODE, domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

// We'll create a <div></div> for page transition
export let pageTransitionDiv

// For single Product, we nned to create a variable to adapt the subject in Contact page
let estimationSubject = false
let buttonDataValue = null

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    if (isDEVMODE) console.log(`Transition Entering Page : ${to.dataset.taxiView}`)

    if (to.getAttribute('data-taxi-view') === 'pageContact') {
      const contactForm = to.querySelector('#contact_form') 
      const subjectField = contactForm.querySelector('.Form__group.--subject .Form__control')
      subjectField.value = buttonDataValue
    }

    estimationSubject = false

    gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 0.8 },
        onStart: () => {
          // Reset window scroll
          window.scrollTo(0, 0)
          // Updating the ScrollTriggers
          ScrollTrigger.refresh()
        },
        onComplete: () => {
          // Display the new page
          done()
          // Removing body className while animating
          domStorage.body.classList.add('is--animating')
          // Removing the pageTransitionDiv from the DOM
          domStorage.body.removeChild(pageTransitionDiv)
        },
      })
      // .from(to, { y: -20 }, 0)
      .to(pageTransitionDiv, { clipPath: 'inset(0% 0% 100% 0%)', overwrite: true }, 0)
      .add(pageIntro, 0)
  }

  onLeave({ from, trigger, done }) {
    if (isDEVMODE) console.log(`Transition Leaving Page : ${from.dataset.taxiView}`)

    // Update the jobApplicationSubject if the trigger (link/button) contains a className
    if (trigger && trigger !== 'popstate' && trigger.dataset.value) {
      buttonDataValue = trigger.dataset.value
      estimationSubject = true
    }

    // We need to create a div for page transitions
    // <div class="PageTransition"></div>
    pageTransitionDiv = document.createElement('div')
    pageTransitionDiv.className = 'PageTransition'
    pageTransitionDiv.innerHTML = `
      <div class="Logo">
        <svg width="125" height="20" viewBox="0 0 125 20" fill="none">
          <path d="M1.90605 6.40909C1.90605 4.50304 2.62677 2.96033 4.06226 1.77501C5.50371 0.59564 7.39189 0 9.73871 0C10.9657 0 12.2047 0.166779 13.4495 0.494381C14.6944 0.821983 15.8142 1.28063 16.803 1.87031L15.7011 7.58845L15.6177 7.61823C14.611 6.92134 13.5627 6.36739 12.4846 5.9564C11.4005 5.54541 10.4594 5.33693 9.65532 5.33693C9.32772 5.33693 9.0716 5.38458 8.89886 5.47393C8.72612 5.56328 8.63678 5.69432 8.63678 5.8611C8.63678 6.06361 8.78569 6.27209 9.08946 6.49248C9.39324 6.71286 10.0306 7.08216 11.0015 7.59441C12.4489 8.34492 13.4912 9.17286 14.1226 10.0663C14.754 10.9657 15.0697 12.0558 15.0697 13.3364C15.0697 15.3318 14.343 16.9281 12.8837 18.1194C11.4244 19.3106 9.47663 19.9063 7.04046 19.9063C5.75984 19.9063 4.49112 19.7395 3.24624 19.4C2.00135 19.0605 0.917285 18.5899 0 17.9824L0.273994 11.7698L0.357384 11.7162C1.38188 12.5978 2.50169 13.2947 3.71084 13.8069C4.91999 14.3192 6.06361 14.5753 7.14768 14.5753C7.53484 14.5753 7.82671 14.5277 8.02923 14.4383C8.23174 14.349 8.333 14.212 8.333 14.0273C8.333 13.7891 8.17814 13.5508 7.8684 13.3126C7.55867 13.0743 6.93325 12.7169 5.99809 12.2404C4.49708 11.4542 3.43684 10.6143 2.82333 9.7268C2.20982 8.8393 1.90009 7.73141 1.90009 6.41504L1.90605 6.40909Z" />
          <path d="M37.4062 0.333557L35.8635 6.46269H29.9786L26.7086 19.5787H19.8349L23.1049 6.46269H17.1128L18.6555 0.333557H37.4062Z" />
          <path d="M37.5969 19.5787H30.062L42.5168 0.333557H50.3793L53.2384 19.5787H46.3647L46.0907 17.0234H39.1634L37.5969 19.5787ZM45.1555 6.76647L42.0463 11.8532H45.7571L45.1496 6.76647H45.1555Z" />
          <path d="M72.6801 8.33299L70.0116 18.6971C68.9097 19.0664 67.7542 19.3464 66.5331 19.5489C65.3121 19.7514 64.1386 19.8527 63.001 19.8527C60.1955 19.8527 57.944 19.0902 56.2524 17.5594C54.5548 16.0287 53.709 13.9916 53.709 11.4422C53.709 7.95773 54.9062 5.18801 57.2947 3.12709C59.6892 1.06618 62.9176 0.0357208 66.9858 0.0357208C68.2307 0.0357208 69.4577 0.154849 70.6728 0.393105C71.882 0.631361 72.9839 0.970876 73.9727 1.41165L72.4061 7.37996H72.3525C71.4174 6.93919 70.4762 6.61754 69.5351 6.40311C68.5881 6.19464 67.5874 6.08742 66.5272 6.08742C65.0976 6.08742 63.8766 6.41503 62.8699 7.07619C61.8633 7.73735 61.22 8.62485 60.946 9.74465C60.9103 9.96504 60.8745 10.1735 60.8507 10.376C60.8209 10.5785 60.809 10.7692 60.809 10.9538C60.809 11.9962 61.1366 12.7943 61.7978 13.3483C62.4589 13.8963 63.4179 14.1703 64.6866 14.1703H65.1512L65.6158 12.3833H62.9235L63.9421 8.33894H72.6861L72.6801 8.33299Z" />
          <path d="M85.933 19.5787H71.9116L76.6946 0.333557H91.0197L89.62 5.96831H82.0315L81.6206 7.61823H88.0535L86.8979 12.2642H80.465L80.054 13.9439H88.113L85.9389 19.5787H85.933Z" />
          <path d="M107.054 19.5787H99.5193L96.3564 13.8308L94.9269 19.5787H88.0532L92.8362 0.333557H100.151C102.605 0.333557 104.517 0.845808 105.881 1.87626C107.245 2.90077 107.93 4.34221 107.93 6.19465C107.93 7.58845 107.501 8.85121 106.637 9.98888C105.774 11.1266 104.564 12.0141 103.01 12.6573L107.054 19.5846V19.5787ZM101.199 6.98686C101.199 6.52821 101.026 6.16487 100.675 5.90279C100.323 5.63475 99.8529 5.50371 99.2453 5.50371H98.3399L97.4048 9.21455H98.3399C99.2393 9.21455 99.9362 9.01799 100.443 8.62487C100.949 8.23174 101.199 7.68375 101.199 6.98686Z" />
          <path d="M109.496 6.40909C109.496 4.50304 110.217 2.96033 111.653 1.77501C113.094 0.59564 114.982 0 117.329 0C118.556 0 119.795 0.166779 121.04 0.494381C122.285 0.821983 123.405 1.28063 124.393 1.87031L123.291 7.58845L123.208 7.61823C122.201 6.92134 121.153 6.36739 120.075 5.9564C118.991 5.54541 118.05 5.33693 117.246 5.33693C116.918 5.33693 116.662 5.38458 116.489 5.47393C116.316 5.56328 116.227 5.69432 116.227 5.8611C116.227 6.06361 116.376 6.27209 116.68 6.49248C116.984 6.71286 117.621 7.08216 118.592 7.59441C120.039 8.34492 121.082 9.17286 121.713 10.0663C122.344 10.9657 122.66 12.0558 122.66 13.3364C122.66 15.3318 121.933 16.9281 120.474 18.1194C119.015 19.3106 117.067 19.9063 114.631 19.9063C113.35 19.9063 112.081 19.7395 110.837 19.4C109.592 19.0605 108.508 18.5899 107.59 17.9824L107.864 11.7698L107.948 11.7162C108.972 12.5978 110.092 13.2947 111.301 13.8069C112.51 14.3192 113.654 14.5753 114.738 14.5753C115.125 14.5753 115.417 14.5277 115.62 14.4383C115.822 14.349 115.923 14.212 115.923 14.0273C115.923 13.7891 115.768 13.5508 115.459 13.3126C115.149 13.0743 114.524 12.7169 113.588 12.2404C112.087 11.4542 111.027 10.6143 110.414 9.7268C109.8 8.8393 109.49 7.73141 109.49 6.41504L109.496 6.40909Z" />
        </svg>
      </div>
    `

    gsap
      .timeline({
        delay: 0.2,
        defaults: { ease: 'power3.inOut', duration: 0.8 },
        onStart: () => {
          // We append the pageTransitionDiv to the body
          document.body.appendChild(pageTransitionDiv)
        },
        onComplete: () => { 
          // Remove the last page
          done()
        }
      })
      .to(from, { y: 20 }, 0)
      .fromTo(pageTransitionDiv, { clipPath: 'inset(100% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
      .fromTo(pageTransitionDiv.children[0], { opacity: 0, scale: 1.1 }, { opacity: 1, scale: 1 }, 0.3)
  }
}
