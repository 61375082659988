import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)
import PageHeaderHome from '../animations/page-header-home'
import DiscoverSections from '../animations/discover-sections'
import RelatedRealisations from '../modules/related-realisations'
import Testimonial from '../modules/testimonial'
import WknSwiper from '../modules/wkn-swiper'

export default class PageHome extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      header: viewStorage.current.querySelector('.PageHeaderHome'),
      scrollButton: viewStorage.current.querySelector('.ScrollIndicator'),
      discover: viewStorage.current.querySelector('.Discover'),
      realisations: viewStorage.current.querySelector('.RelatedRealisations'),
      testimonialSwiper: viewStorage.current.querySelector('.Testimonial .swiper'),
      productsCategoriesSwiper: viewStorage.current.querySelector('.HomeProducts .swiper')
    }

    this.init()

  }

  init() {
    if (this.DOM.header) this.pageHeader = new PageHeaderHome(this.DOM.header)
    if (this.DOM.scrollButton) this.DOM.scrollButton.addEventListener('click', this.scrollTo.bind(this))
    if (this.DOM.discover) this.discoverSections = new DiscoverSections(this.DOM.discover)
    if (this.DOM.realisations) this.realisations = new RelatedRealisations(this.DOM.realisations)
    if (this.DOM.testimonialSwiper) this.testimonial = new Testimonial(this.DOM.testimonialSwiper)
    
    if (this.DOM.productsCategoriesSwiper) {
      this.productsCategoriesSwiperModule = new WknSwiper(this.DOM.productsCategoriesSwiper, { 
        autoplay: { delay: 1500 },
        spaceBetween: 15, 
        loop: true,
        slidesPerView: 'auto',
        breakpoints: {
          768: { slidesPerView: 4 }
        }})
    }
  }

  scrollTo(e) {
    e.preventDefault()

    const { currentTarget } = e
    const nextSection = currentTarget.parentNode.nextElementSibling

    if (!nextSection) return

    gsap.to(window, { scrollTo: nextSection, ease: 'circ.inOut', duration: 1 })
  }

  onLeaveCompleted() {
    this.DOM.scrollButton && this.DOM.scrollButton.removeEventListener('click', this.scrollTo.bind(this))
    this.pageHeader && this.pageHeader.destroy()
    this.discoverSections && this.discoverSections.destroy()
    this.testimonial && this.testimonial.destroy()
    this.productsCategoriesSwiperModule && this.productsCategoriesSwiperModule.destroy()
  }
}
