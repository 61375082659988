import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import Swiper, { Autoplay } from 'swiper'
Swiper.use([Autoplay])
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class ArchiveProducts extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      form: viewStorage.current.querySelector('.PageHeaderArchiveProducts form'),
      productsCategoriesSwiper: viewStorage.current.querySelector('.PageHeaderArchiveProducts .swiper'),
      searchedProducts: document.getElementById('SearchedProducts'),
      searchProductForm: document.getElementById('searchProductForm'),
      productPagination: document.getElementById('productPagination'),
      totalProducts: document.querySelector('#totalProducts'),
      posts_per_page: document.querySelector('#posts_per_page'),
      highlightProductsSwiper: viewStorage.current.querySelector('.HighlightProducts .swiper'),
      allProductsSection: viewStorage.current.querySelector('section.AllProducts')
    }

    this.init()

  }

  init() {
    const {
      form = null,
      swiper = null,
      highlightProductsSwiper = null,
    } = this.DOM

    let json_data = document.getElementById('json_data');
    if (json_data) {
      if (json_data.innerHTML) {
        json_data = JSON.parse(json_data.innerHTML);
        this.urlajax = json_data.urls.ajax.search_product;
      }
    }
    this.page = 1;
    this.search = '';
    this.posts_per_page = posts_per_page.value;

    this.no_result = document.createElement('p')
    this.no_result.textContent = 'Aucun résultat n\'a été trouvé'

    if (form) this.initSearchForm()

    if (this.DOM.productsCategoriesSwiper) {
      this.productsCategoriesSwiperModule = new WknSwiper(this.DOM.productsCategoriesSwiper, { 
        autoplay: { delay: 1500 },
        spaceBetween: 15, 
        loop: true,
        slidesPerView: 'auto',
        breakpoints: {
          768: { slidesPerView: 4 }
        }})
    }

    if (highlightProductsSwiper) this.highlightProductsSwiper = new WknSwiper(highlightProductsSwiper, {
      loop: true,
      autoplay: {
        delay: 2000,
      },
    })
  }

  initPaginationListeners() {
    const { productPagination } = this.DOM;
    let buttons = productPagination.querySelectorAll('a');
    // console.log('1');
    buttons.forEach((button, index) => {
      // console.log('2');
      const m = button.addEventListener('click', (e) => {
        // console.log('3');
        e && e.preventDefault()

        document.body.classList.add('--loading')

        if (1) {
          this.page = parseInt(e.target.dataset.page);

          this.runSearch(true);
        }
      });
    });
  }
  initSearchForm() {
    const { searchProductForm, posts_per_page } = this.DOM
    // Script here
    let search_product = document.getElementById('search_product');
    search_product.addEventListener('keyup', (e) => {
      // console.log('Bring back to first page');
      this.page = 1;
      this.newResearch(e);
    });
    posts_per_page.addEventListener('change', (e) => {
      // console.log('Bring back to first page');
      this.page = 1;
      this.posts_per_page = e.target.value;
      this.runSearch();
    });
    searchProductForm.addEventListener('submit', (e) => {
      // console.log('Bring back to first page');
      this.page = 1;
      this.newResearch(e);
    });
    this.runSearch();
  }

  newResearch(e) {
    e && e.preventDefault();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.search = e.target.value;
      // console.log('Bring back to first page');
      this.page = 1;
      this.runSearch();
    }, 500);
  }

  onSuccess(responseText, scrollToTop) {

    const { searchedProducts, productPagination, totalProducts } = this.DOM
    const json = JSON.parse(responseText);
    searchedProducts.innerHTML = json.productGrid_html;
    productPagination.innerHTML = json.productPagination_html;
    totalProducts.innerHTML = json.totalProducts;

    this.no_result && this.no_result.remove()

    if (json.productGrid_html) {
      searchedProducts.innerHTML = json.productGrid_html
    } else {
      searchedProducts.innerHTML = ''
      searchedProducts.parentNode.appendChild(this.no_result)
      productPagination.remove()
    }

    json.productPagination_html && (productPagination.innerHTML = json.productPagination_html)

    document.body.classList.remove("--loading");

    if (scrollToTop) {
      gsap.to(window, {
        scrollTo: this.DOM.allProductsSection,
        ease: "expo.inOut",
        duration: 1.5
      });
    }

    this.initPaginationListeners()
  }

  runSearch(scrollToTop) {
    const formData = new FormData();
    formData.append('page', this.page);
    formData.append('search', this.search);
    formData.append('posts_per_page', this.posts_per_page);

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.urlajax, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText, scrollToTop)
            break
          case 422:
            break
          default:
            this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  onLeaveCompleted() {
    this.productsCategoriesSwiperModule && this.productsCategoriesSwiperModule.destroy()
    this.highlightProductsSwiper && this.highlightProductsSwiper.destroy()
  }
}
